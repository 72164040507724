#brideandgroom {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#brideandgroom div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#bride,
#groom {
  margin: 1rem;
  border-radius: 10px;
}

#brideandgroom img {
  max-width: 250px;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  #brideandgroom {
    display: flex;
    flex-direction: column;
  }
}
