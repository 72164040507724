#countdown {
  /* positioning within the header grid */
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 3;
  grid-column-end: 4;
  margin: 0 auto;
  /* elements within the countdown div */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px dotted rgb(191 202 233);
  width: 30%;
  height: 0;
  padding: 180px 0;
  
  line-height: 2.2rem;
  background-color:rgb(191 202 233);
  opacity: 0.6;
}

#countdown > span:first-of-type {
  font-weight: bold;
  font-size: 2rem;
  color:rgb(64 56 56)
}

#countdown > span:last-of-type {
  font-size: 1.5rem;
  color: #c6540d;
}

/* vertical mobile display */
@media only screen and (max-width: 600px) {
  #countdown {
    grid-row-end: 2;
    background-color: rgb(191 202 233);
    width: 100%;
    height: 0;
    padding: 120px 0;
  }

  #countdown > span:first-of-type {
    font-size: 1.2rem;
  }

  #countdown > span:last-of-type {
    color: rgb(164, 85, 28);
    font-size: 1.3rem;
  }
}
