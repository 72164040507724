html {
  box-sizing: border-box;
  background: #eeeeee;
  font-weight: 200;
}

body {
  margin: 0;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.loginContainer {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  background-image: url("/images/pattern.png"),
    linear-gradient(var(--palepink), var(--pink));
  background-repeat: repeat;
}

.loginContainer > form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 30rem;
  margin: 2rem 2rem 2.4rem 2rem;
  padding: 2rem 2.4rem;
  background-color: white;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: center;
}

.loginContainer > form > #inputFields > input {
  display: inline-block;
  width: 100%;
  line-height: 3.125rem;
  height: 3.125rem;
  border: 1px solid #e6e6e6;
  border-bottom-width: 3px;
  border-radius: 5px;
  outline: 0;
  transition: all 0.3s ease;
  margin: 0.5rem 0;
  background-color: var(--palepink);
  text-align: center;
  font-family: "Lato";
}

.loginContainer > form > p {
  text-align: center;
  margin: 1rem 0;
  line-height: 1.5rem;
  font-size: 1rem;
}

.loginContainer > form > button {
  border-radius: 20px;
  padding: 15px 60px;
  margin-top: 1rem;
  width: 100%;
  font-size: 1em;
  letter-spacing: 2px;
  color: var(--darkgrey);
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
}

.flags {
  background-repeat: no-repeat;
  background-size: cover;
  height: 1.2rem;
  width: 2rem;
  margin: 0.5rem;
  outline: none;
  cursor: pointer;
}

#italian {
  background-image: url("/images/it.png");
}

#english {
  background-image: url("/images/gb.png");
}

#languages {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 0.5rem;
  left: 0;
}

@media only screen and (max-height: 600px) {
  .loginContainer {
    height: auto;
  }

  .loginContainer > form {
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 600px) {
  .loginContainer > form > #inputFields > input {
    font-size: 1rem;
  }
}

html {
  font: normal 16px;
  color: var(--black);
  background-color: var(--pearlgrey);
  --pink: rgb(237, 190, 173);
  --palepink: rgb(244, 223, 207);
  --darkgrey: rgb(94, 96, 97);
  --lightgreen: rgb(241, 242, 214);
  --olivegreen: rgb(108, 123, 104);
  --pearlgrey: rgb(235, 234, 233);
}

p,
li,
span {
  font-family: "Lato", sans-serif;
  color: var(--black);
  font-size: 1rem;
}

h1 {
  font-family: "Cookie", cursive;
  font-size: 4rem;
}

h2 {
  font-family: "Cookie", cursive;
  font-size: 2.5rem;
  color: var(--olivegreen);
}

h3 {
  font-family: "Playfair Display", serif;
  font-size: 1.3rem;
}

a {
  opacity: 0.75;
  text-decoration: none;
  color: var(--olivegreen);
  font-weight: bold;
  display: block;
}

a:hover {
  opacity: 1;
  color: var(--pink);
  text-decoration: none;
}

.section {
  padding: 15px 0;
  box-sizing: border-box;
  margin-top: 1.5rem;
}

.section p {
  max-width: 50rem;
  margin: 1rem auto;
  padding: 10px 20px;
  text-align: center;
  line-height: 2;
}

.section h2 {
  text-transform: capitalize;
  text-align: center;
}

#loading,
#notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

#loading p,
#notfound p {
  font-size: 2rem;
  color: var(--olivegreen);
  padding: 1rem;
}

#loading i {
  color: var(--pink);
}

#notfound button {
  border-radius: 20px;
  padding: 15px 60px;
  margin-top: 2rem;
  border: 1px solid var(--darkgrey);
  font-size: 1em;
  letter-spacing: 2px;
  color: var(--darkgrey);
  background-color: var(--pearlgrey);
  cursor: pointer;
  outline: none;
}

/* vertical mobile display */
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1rem;
  }
}

#menu {
  margin-left: 2rem;
}

#menu li,
link {
  line-height: 4.1;
  list-style: none;
  display: none;
}

.description {
  color: var(--lightgreen);
  text-decoration: none;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.fas {
  color: var(--lightgreen);
  margin-right: 1rem;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  display: none;
}

/* #menu li link span:focus {
  display: block;
  color: var(--lightgreen);
  transition: all 0.5s;
} */

#trigger,
#burger,
#burger:before,
#burger:after {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: var(--lightgreen);
  width: 1.7rem;
  height: 0.27rem;
  transition: 0.2s ease;
  cursor: pointer;
  z-index: 1;
}

/* label of the burger menu */
#trigger {
  height: 2rem;
  background: none;
}

#burger:before {
  content: " ";
  top: 10px;
  left: 0;
}
#burger:after {
  content: " ";
  top: 20px;
  left: 0;
}

#menu-toggle:checked + #trigger + #burger {
  top: 28px;
  transform: rotate(180deg);
  transition: transform 0.2s ease;
}

#menu-toggle:checked + #trigger + #burger:before {
  width: 18px;
  top: -2px;
  left: 18px;
  transform: rotate(45deg) translateX(-5px);
  transition: transform 0.2s ease;
}
#menu-toggle:checked + #trigger + #burger:after {
  width: 18px;
  top: 2px;
  left: 18px;
  transform: rotate(-45deg) translateX(-5px);
  transition: transform 0.2s ease;
}

#menu {
  position: absolute;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  background-color: var(--olivegreen);
  width: 100%;
  height: auto;
  justify-content: space-around;
}

#menu-toggle:checked + #trigger + #burger + #menu {
  animation: checked-anim 1s ease both;
}
#menu-toggle:checked + #trigger ~ #menu > li,
link {
  display: block;
}

@media only screen and (max-width: 600px) {
  #menu li,
  link {
    line-height: 3.5;
    padding: 0 0.2rem;
  }

  #menu {
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  .description {
    font-size: 0.6rem;
  }

  .description {
    display: none;
  }
}

@media only screen and (min-width: 600px) and (max-width: 850px) {
  #menu li:first-of-type,
  link:first-of-type {
    margin-left: 3rem;
  }
}

.header {
  background-image: url("https://images.unsplash.com/photo-1496661415325-ef852f9e8e7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1399&q=80");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
}

#tagline {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: var(--olivegreen);
  border-radius: 10px;
  color: var(--lightgreen);
  padding: 2rem;
  margin: 0 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#tagline h1,
h3 {
  margin: 0.5rem;
}

/* vertical mobile display */
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2.7rem;
  }

  h3 {
    font-size: 1.2rem;
  }
}

#countdown {
  /* positioning within the header grid */
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 3;
  grid-column-end: 4;
  margin: 0 auto;
  /* elements within the countdown div */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px dotted rgb(191 202 233);
  width: 30%;
  height: 0;
  padding: 180px 0;
  
  line-height: 2.2rem;
  background-color:rgb(191 202 233);
  opacity: 0.6;
}

#countdown > span:first-of-type {
  font-weight: bold;
  font-size: 2rem;
  color:rgb(64 56 56)
}

#countdown > span:last-of-type {
  font-size: 1.5rem;
  color: #c6540d;
}

/* vertical mobile display */
@media only screen and (max-width: 600px) {
  #countdown {
    grid-row-end: 2;
    background-color: rgb(191 202 233);
    width: 100%;
    height: 0;
    padding: 120px 0;
  }

  #countdown > span:first-of-type {
    font-size: 1.2rem;
  }

  #countdown > span:last-of-type {
    color: rgb(164, 85, 28);
    font-size: 1.3rem;
  }
}

.image_grid > li {
  list-style: none;
  height: 350px;
  padding: 1rem;
  background-clip: content-box;
  background-size: cover;
  background-position: center;
}

.image_grid > li.small {
  flex-basis: 40%;
}

.image_grid > li.large {
  flex-basis: 33%;
}

.image_grid {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

hr {
  width: 250px;
  height: 2.5px;
  background-color: var(--olivegreen);
  border: 0;
  margin-bottom: 50px;
}

.location .image_grid > li:first-child {
  background-image: url("/images/img1.jpeg");
}

.location .image_grid > li:nth-child(2) {
  background-image: url("/images/img2.avif");
  background-position: top;
}

.location .image_grid > li:nth-child(3) {
  background-image: url("/images/img3.jpg");
}

.location .image_grid > li:last-child {
  background-image: url("/images/img4.jpg");
}

#directions {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 1rem;
}

#colorconvention {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 2;
}

#colorconvention li {
  margin-right: 1rem;
  text-transform: uppercase;
  font-weight: bold;
}

.colorlegend {
  color: var(--darkgrey);
}

div.transportList {
  font-size: 1rem;
  text-align: justify;
  margin: 2.5px;
  width: 100%;
  line-height: 2;
  padding: 10px 20px;
}

div.transportList:hover {
  border: 2px solid var(--pink);
  margin: 0;
}

div.transportList h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--olivegreen);
  text-align: left;
}

div.transportList i {
  margin-right: 0.5rem;
  color: var(--olivegreen);
}

#importantInformation {
  margin: 3rem auto;
  padding: 2rem;
  max-width: 775px;
  word-wrap: normal;
  border-radius: 5px;
  border: 1px solid var(--pink);
  box-shadow: 5px 5px var(--palepink);
}

@media only screen and (max-width: 600px) {
  .image_grid li.small {
    flex-basis: 104%;
  }

  .image_grid li.large {
    flex-basis: 84%;
  }

  #directions {
    flex-wrap: wrap;
  }
}

#brideandgroom {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#brideandgroom div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#bride,
#groom {
  margin: 1rem;
  border-radius: 10px;
}

#brideandgroom img {
  max-width: 250px;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  #brideandgroom {
    display: flex;
    flex-direction: column;
  }
}

#gifts {
  height: 100vh;
}

#gifts .image_grid > li:first-child {
  background-image: url(/static/media/DSC00264.9e6e3fe8.JPG);
}

#gifts .image_grid > li:nth-child(2) {
  background-image: url("/static/media/WhatsApp Image 2024-02-23 at 10.30.24 PM.f5b97f01.jpeg");
}




#gifts .image_grid > li:nth-child(3) {
  background-image: url("/static/media/WhatsApp Image 2024-02-26 at 11.14.06 AM.7586a542.jpeg");
}
#gifts .image_grid > li:last-child {
  background-image: url(/static/media/DSC00477.ce84b1c1.JPG);
}

#bankdetails {
  padding: 0;
  margin-top: 2rem;
}

#bankdetails p {
  margin: 0 auto;
  padding: 0;
}

