#gifts {
  height: 100vh;
}

#gifts .image_grid > li:first-child {
  background-image: url("./DSC00264.JPG");
}

#gifts .image_grid > li:nth-child(2) {
  background-image: url("./WhatsApp\ Image\ 2024-02-23\ at\ 10.30.24\ PM.jpeg");
}




#gifts .image_grid > li:nth-child(3) {
  background-image: url("./WhatsApp\ Image\ 2024-02-26\ at\ 11.14.06\ AM.jpeg");
}
#gifts .image_grid > li:last-child {
  background-image: url("./DSC00477.JPG");
}

#bankdetails {
  padding: 0;
  margin-top: 2rem;
}

#bankdetails p {
  margin: 0 auto;
  padding: 0;
}
